import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  let location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      const decodedJwt = parseJwt(token);
      // console.log("🚀 ~ file: AuthVerify.js:19 ~ useEffect ~ decodedJwt:", decodedJwt)     

      if (decodedJwt === null || decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
      }
    }
    else if (token === null){
      props.logOut();
    }
  }, [location, props]);

  return ;
};

export default AuthVerify;