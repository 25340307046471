import React, { useState } from "react";
import Header from "components/Header";
import {
  InputBase,
  Box,
  IconButton,
  useTheme,
  Button,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Divider,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FlexBetween from "components/FlexBetween";
import { GetPackageDetailByTracking, PatchShippingFee } from "api/packages";
import Swal from "sweetalert2";
import {
  getScanText,
  playAlertSound,
  playCorrectSound,
  playIncorrectSound,
} from "utils";
import Scanner from "components/Scanner";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

function selectAndFocus(elementId) {
  document.getElementById(elementId).select();
  document.getElementById(elementId).focus();
}

const ShippingFee = () => {
  const theme = useTheme();
  const [tracking, setTracking] = useState("");
  const [searchTracking, setSearchTracking] = useState("");
  const [open, setOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  // Data to send to back-end
  const [packageId, setPackageId] = useState("");
  const [description, setDescription] = useState("");
  const [orderId, setOrderId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [shippingFee, setShippingFee] = useState(0);

  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState(0);

  function clearForm() {
    setPackageId("");
    setTracking("");
    setOrderId("");
    setDescription("");
    setRemark("");
    setAmount("");
    setQuantity(0);
    setShippingFee(0);
    setSearchTracking("");
    document.getElementById("searchTracking").focus();
  }

  const handlerSerachTrackingNumber = () => {
    // e.preventDefault();
    GetPackageDetailByTracking(searchTracking).then(async (res) => {
      if (res.status === 200) {
        let data = res.data; //Get current station and routeId from package
        console.log(res.data);
        setPackageId(data._id);
        setTracking(data.tracking);
        setOrderId(data.orderId);
        setDescription(data.description);
        setRemark(data.remark);
        setAmount(data.amount);
        setQuantity(data.quantity);
        setShippingFee(data.shippingFee);
        document.getElementById("shippingFee").select();
        document.getElementById("shippingFee").focus();
      } else if (res.status === 204) {
        playAlertSound();
        Swal.fire({
          title: "Data not found",
          icon: "warning",
          text: "This tacking number doesn't exist",
          timer: 1500,
        });
        selectAndFocus("tracking");
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res.message,
          timer: 1500,
        });
        selectAndFocus("tracking");
      }
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    PatchShippingFee(
      packageId,
      tracking,
      orderId,
      description,
      remark,
      amount,
      quantity,
      shippingFee
    )
      .then((res) => {
        if (res.status === 200) {
          playCorrectSound();
          Swal.fire({
            icon: "success",
            title: `Success!`,
            text: `This package has been updated`,
            timer: 1500,
          });
        }
      })
      .finally(clearForm());
  };
  return (
    <Box m="0 1rem">
      <Grid maxWidth={600} container spacing={2}>
        <Grid item xl={12} md={12} xs={12}>
          <Header title="Shipping Fee" />
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          {/* Scan package section */}
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth>
                <FlexBetween
                  backgroundColor={theme.palette.background.alt}
                  borderRadius="9px"
                  gap="3rem"
                  p="0.5rem 1rem"
                >
                  <InputBase
                    placeholder="Tracking number"
                    id="searchTracking"
                    autoFocus
                    value={searchTracking}
                    onChange={(e) => setSearchTracking(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handlerSerachTrackingNumber();
                      }
                    }}
                  />
                  {isNonMobile ? (
                    <IconButton onClick={handlerSerachTrackingNumber}>
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setOpen(true)}>
                      <CameraAltIcon />
                    </IconButton>
                  )}
                </FlexBetween>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          {/*  Form section  */}
          <form onSubmit={formSubmitHandler}>
            <Grid container spacing={2}>
              <Grid item xl={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Tracking Number</InputLabel>
                  <OutlinedInput
                    label="Tracking Number"
                    id="tracking"
                    value={tracking}
                    required={true}
                    onChange={(e) => setTracking(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Order ID</InputLabel>
                  <OutlinedInput
                    type="text"
                    label="Order ID"
                    value={orderId}
                    id="orderId"
                    onChange={(e) => setOrderId(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    minRows={3}
                    maxRows={5}
                    label="Description"
                    value={description}
                    id="decscription"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid item xl={12} md={12} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    minRows={2}
                    maxRows={5}
                    label="Remark"
                    value={remark}
                    id="remark"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={4} md={4} xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Amount</InputLabel>
                  <OutlinedInput
                    type="number"
                    label="Amount"
                    value={amount}
                    id="amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={4} md={4} xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Quantity</InputLabel>
                  <OutlinedInput
                    type="number"
                    value={quantity}
                    label="Quantity"
                    id="quantity"
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={4} md={4} xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Shipping Fee</InputLabel>
                  <OutlinedInput
                    value={shippingFee}
                    type="number"
                    label="Shipping Fee"
                    id="shippingFee"
                    onChange={(e) => setShippingFee(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xl={12} md={12} xs={12} mt={2}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <FormControl>
                  <Button
                    variant="contained"
                    type="submit"
                    color="secondary"
                    size="large"
                  >
                    Submit
                  </Button>
                </FormControl>
              </Box>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Scanner
        open={open}
        setOpen={setOpen}
        onDecode={(result) => {
          if (result) {
            console.log(result);
            setTracking(() => getScanText(result));
            handlerSerachTrackingNumber();
          }
        }}
      />
    </Box>
  );
};

export default ShippingFee;
