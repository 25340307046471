import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { QueryProvinces, QueryDistrists, QueryVillages } from "api/general";
import { PatchCustomer, DeleteCustomer, GetCustomersList } from "api/customers";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { CardActions } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CancelIcon from "@mui/icons-material/Cancel";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  EditAttributesOutlined,
  Favorite,
  MoreVert,
  Share,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { capitalizeFirstLetter } from "utils";
import { useNavigate } from "react-router-dom";
import {
  Box,
  useTheme,
  Button,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  styled,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
  Link,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  zIndex: 1,
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function CustomerCard({ customer, avatarColor }) {
  const navigate = useNavigate();

  // var customer = customerDetail;
  // const [customer, setCustomer] = useState({})
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [name, setName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [villages, setVillages] = useState([]);
  const [villageId, setVillageId] = useState("");
  const [remark, setRemark] = useState("");
  const [validflag, setValidflag] = useState(true);

  useEffect(() => {
    QueryProvinces().then((res) => setProvinces(res.data));
  }, []);

  useEffect(() => {
    if (provinceId) {
      setVillageId("");
      setVillages([]);
      setDistrictId("");
      setDistricts([]);
      QueryDistrists(provinceId).then((res) => setDistricts(res.data));
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId) {
      setVillageId("");
      QueryVillages(districtId).then((res) => setVillages(res.data));
    }
  }, [districtId]);

  const buttonEditHandler = (event, customer) => {
    const {
      _id,
      name: customerName,
      whatsapp,
      province,
      district,
      village,
      remark,
      validflag,
    } = customer;
    console.log(
      "🚀 ~ file: CustomerCard.jsx:129 ~ buttonEditHandler ~ customer:",
      customer
    );

    setProvinceId(province.pr_id);
    QueryDistrists(province.pr_id).then((res) => {
      setDistricts(res.data);
      setDistrictId(district.dt_id);
    });
    QueryVillages(district.dt_id).then((res) => {
      setVillages(res.data);
      setVillageId(village.vill_id);
    });

    setName(customerName);
    setWhatsapp(whatsapp);
    setCustomerId(_id);
    setRemark(remark);
    setValidflag(validflag);
    setOpenEditDialog(true);
  };

  const formSubmittedHandler = (e) => {
    e.preventDefault();
    const provinceObj = provinces.find(
      (item) => item.pr_id === provinceId.toString()
    );
    const districtObj = districts.find(
      (item) => item.dt_id === districtId.toString()
    );
    const villageObj = villages.find(
      (item) => item.vill_id === villageId.toString()
    );

    PatchCustomer(
      customerId,
      name,
      whatsapp,
      provinceObj,
      districtObj,
      villageObj,
      validflag,
      remark
    ).then((res) => {
      if (res.status === 200) {
        // update object data in rows

        setOpenEditDialog(false);
        Swal.fire({
          title: "Updated!",
          icon: "success",
          text: res.message,
          timer: 1500,
        });
      } else if (res.status === 400) {
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
        });
      } else {
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: res.message,
        });
      }
    });
  };
  
  return (
    <div>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: "#" + avatarColor, color: "white" }}
              aria-label="recipe"
            >
              {customer.name.toUpperCase().slice(0, 1)}
            </Avatar>
          }
          action={
            <IconButton
              aria-label="Edit"
              onClick={(event) => {
                buttonEditHandler(event, customer);
              }}
            >
              <ModeEditOutlineIcon />
            </IconButton>
          }
          title={
            capitalizeFirstLetter(customer.name) + " - " + customer.whatsapp
          }
          subheader={customer.province.pr_name}
        />
        <CardContent>
          <IconButton aria-label="Arrived">
            <BeenhereIcon /> Arrived
          </IconButton>
          <IconButton>
            <LocalShippingIcon aria-label="In-progress" /> In-progress
          </IconButton>
          <IconButton>
            <CancelIcon aria-label="Cancel" /> Cancel
          </IconButton>
        </CardContent>
      </Card>

      <div>
        <form onSubmit={formSubmittedHandler}>
          <BootstrapDialog
            onClose={() => setOpenEditDialog(false)}
            aria-labelledby="customized-dialog-title"
            open={openEditDialog}
            TransitionComponent={Transition}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => setOpenEditDialog(false)}
            >
              Edit Customer
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel htmlFor="name">Name</InputLabel>
                  <Input
                    id="name"
                    aria-describedby="name-helper-text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required={true}
                    value={name}
                  />
                  <FormHelperText id="name-helper-text">
                    Customer name.
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel htmlFor="whatsapp">Whatsapp</InputLabel>
                  <Input
                    type="number"
                    id="whatsapp"
                    aria-describedby="whatsapp-helper-text"
                    onChange={(e) => {
                      setWhatsapp(e.target.value);
                    }}
                    required={true}
                    value={whatsapp}
                  />
                  <FormHelperText id="whatsapp-helper-text">
                    Whatsapp number example: 8562077790708.
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel>Province / ແຂວງ</InputLabel>
                  <Select
                    id="province"
                    defaultValue=""
                    value={!provinceId ? "" : provinceId}
                    label="Province / ແຂວງ"
                    onChange={(event) => setProvinceId(event.target.value)}
                    required={true}
                  >
                    {provinces &&
                      provinces.map(({ pr_id, pr_name, pr_name_en }) => {
                        return (
                          <MenuItem key={pr_id} value={pr_id}>
                            {pr_name} / {pr_name_en}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel>District / ເມືອງ</InputLabel>
                  {districts && (
                    <Select
                      id="districts"
                      // defaultValue=""
                      defaultValue=""
                      value={districtId === "" ? "" : districtId}
                      label="District / ເມືອງ"
                      onChange={(event) => setDistrictId(event.target.value)}
                      disabled={!Boolean(provinceId)}
                      required={true}
                    >
                      {districts &&
                        districts.map(({ dt_id, dt_name, dt_name_en }) => {
                          return (
                            <MenuItem key={dt_id} value={dt_id}>
                              {dt_name} / {dt_name_en}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                </FormControl>
              </Box>

              <Box>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel> Village / ບ້ານ </InputLabel>
                  {villages && (
                    <Select
                      id="villages"
                      value={!villageId ? "" : villageId}
                      label="Village / ບ້ານ"
                      onChange={(event) => setVillageId(event.target.value)}
                      disabled={!districtId}
                      required={true}
                    >
                      {villages &&
                        villages.map(({ vill_id, vill_name, vill_name_en }) => {
                          return (
                            <MenuItem key={vill_id} value={vill_id}>
                              {vill_name}{" "}
                              {!!vill_name_en ? ` / ${vill_name_en}` : ""}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth>
                  <TextField
                    value={remark}
                    multiline
                    maxRows={5}
                    label="Remark / ໝາຍເຫດ"
                    // placeholder="Description"
                    id="remark"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={validflag}
                      onChange={() => setValidflag(!validflag)}
                    />
                  }
                  label="Validflag"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <FormControl>
                <Button
                  disabled={!villageId || !districtId || !provinceId}
                  variant="contained"
                  color="secondary"
                  autoFocus
                  // type="submit"
                  size="large"
                  onClick={formSubmittedHandler}
                >
                  Save changes
                </Button>
              </FormControl>
            </DialogActions>
          </BootstrapDialog>
        </form>
      </div>
    </div>
  );
}

export default CustomerCard;
