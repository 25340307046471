import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { QueryProvinces, QueryDistrists, QueryVillages } from "api/general";
import { PatchCustomer, DeleteCustomer, GetCustomersList } from "api/customers";
import {
  Box,
  useTheme,
  Button,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  styled,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
  Link,
  Grid,
  InputBase,
} from "@mui/material";
// import { useGetCustomersQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import CustomerCard from "components/CustomerCard";
import FlexBetween from "components/FlexBetween";
import SearchIcon from "@mui/icons-material/Search";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  zIndex: 1,
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CustomerListCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [customerId, setCustomerId] = useState("");
  const [name, setName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [villages, setVillages] = useState([]);
  const [villageId, setVillageId] = useState("");
  const [remark, setRemark] = useState("");
  const [validflag, setValidflag] = useState(true);

  useEffect(() => {
    QueryProvinces().then((res) => setProvinces(res.data));
  }, []);

  useEffect(() => {
    if (provinceId) {
      setVillageId("");
      setVillages([]);
      setDistrictId("");
      setDistricts([]);
      QueryDistrists(provinceId).then((res) => setDistricts(res.data));
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId) {
      setVillageId("");
      QueryVillages(districtId).then((res) => setVillages(res.data));
    }
  }, [districtId]);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");

  // values to set in DataGrid
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  function RefreshCutomersList(page, pageSize, sort, search) {
    setIsLoading(true);
    GetCustomersList(search, page, pageSize, sort).then((res) => {
      if (res.status === 200) {
        setRows(res.data.customers);
        setTotalCount(res.data.total);
        setIsLoading(false);
      } else {
        console.log(res.message);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    // console.log(sort)
    RefreshCutomersList(page, pageSize, sort, search);
  }, [search, page, pageSize, sort]);

  const buttonEditHandler = (event, cellValues) => {
    const {
      _id,
      name: customerName,
      whatsapp,
      province,
      district,
      village,
      remark,
      validflag,
    } = cellValues.row;

    setProvinceId(province.pr_id);
    QueryDistrists(province.pr_id).then((res) => {
      setDistricts(res.data);
      setDistrictId(district.dt_id);
    });
    QueryVillages(district.dt_id).then((res) => {
      setVillages(res.data);
      setVillageId(village.vill_id);
    });

    setName(customerName);
    setWhatsapp(whatsapp);
    setCustomerId(_id);
    setRemark(remark);
    setValidflag(validflag);
    setOpenEditDialog(true);
  };

  const buttonDeleteHandler = (event, cellValues) => {
    Swal.fire({
      title: `Delete ${cellValues.row.name} ?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteCustomer(cellValues.row._id).then((res) => {
          if (res.status === 200) {
            // DELETE SELECTED ROW FROM ARRAY "data.customers"
            setRows(() => {
              return rows.filter((item) => item._id !== cellValues.id);
            });

            Swal.fire({
              title: "Deleted!",
              text: res.message,
              icon: "success",
              timer: 1500,
            });
          } else if (res.status === 400) {
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: `Status ${res.status} - ${res.data.message}`,
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: res.message,
              showConfirmButton: true,
            });
          }
        });
      }
    });
  };

  const formSubmittedHandler = (e) => {
    e.preventDefault();
    const provinceObj = provinces.find(
      (item) => item.pr_id === provinceId.toString()
    );
    const districtObj = districts.find(
      (item) => item.dt_id === districtId.toString()
    );
    const villageObj = villages.find(
      (item) => item.vill_id === villageId.toString()
    );

    PatchCustomer(
      customerId,
      name,
      whatsapp,
      provinceObj,
      districtObj,
      villageObj,
      validflag,
      remark
    ).then((res) => {
      if (res.status === 200) {
        // update object data in rows
        let objIndex = rows.findIndex((obj) => obj._id === customerId);
        rows[objIndex].name = name;
        rows[objIndex].whatsapp = whatsapp;
        rows[objIndex].province = provinceObj;
        rows[objIndex].district = districtObj;
        rows[objIndex].village = villageObj;
        rows[objIndex].validflag = validflag;
        rows[objIndex].remark = remark;

        setOpenEditDialog(false);
        Swal.fire({
          title: "Updated!",
          icon: "success",
          text: res.message,
          timer: 1500,
        });
      } else if (res.status === 400) {
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
        });
      } else {
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: res.message,
        });
      }
    });
  };

  const searchtHandler = (e) => {
    console.log(e.target.value);
  }
  var randomColor;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <FormControl fullWidth>
            <FlexBetween
              backgroundColor={theme.palette.background.alt}
              borderRadius="9px"
              gap="3rem"
              p="0.5rem 1rem"
            >
              <InputBase
                placeholder="Name, WhatsApp number ..."
                id="search"
                autoFocus
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchtHandler(e);
                  }
                }}
              />
              <IconButton onClick={searchtHandler}>
                <SearchIcon />
              </IconButton>
            </FlexBetween>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {rows.map((customer) => {
          randomColor = Math.floor(Math.random() * 16777215).toString(16);
          return (
            <Grid item key={customer._id} xs={12} md={6} lg={4}>
              <CustomerCard customer={customer} avatarColor={randomColor} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CustomerListCard;
