import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useState } from "react";
import qrBarcodeSupportImg from "assets/qr-barcode-support.jpg";
import { useTheme } from "@emotion/react";
import FlexBetween from "./FlexBetween";
import CloseIcon from "@mui/icons-material/Close";

function Scanner({ open, setOpen, onDecode }) {
  const [inprogress, setInproggress] = useState(false);
  const theme = useTheme();
  const handleClose = () => {
    setTimeout(() => setOpen(false), 0);
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        <FlexBetween>
          <Box>
            <Typography variant="h3" color={theme.palette.secondary[100]}>
              Camera Scanner
            </Typography>
          </Box>
          <Box>
            <IconButton size="large" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </FlexBetween>
      </DialogTitle>
      <DialogContent>
        <Box maxWidth={600}>
          <QrScanner
            onDecode={(result) => {
              if (!inprogress) setInproggress(true);
              if (result && !inprogress) {
                setTimeout(() => {
                  onDecode(result);
                  setInproggress(false);
                }, 1500);
              }
            }}
            onError={(err) => {
              console.error(err?.message);
            }}
          />
          <Box
            component="img"
            sx={{
              width: "100%",
            }}
            src={qrBarcodeSupportImg}
          />
          <Box margin={2}>
            <Typography variant="h3" textAlign={"center"}>
              {" "}
              Scanner support both 1D and 2D barcode.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ px: 5, my: 5, mr: 2 }}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Scanner;
